import React, { useState } from "react";
import {
  Button,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Input,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Dialog } from "@mui/material";
import { useSnackbar } from "notistack";

import { IAccount, IUser } from "../../../../../types";
import { UsersPanel } from "./UsersPanel";
import { useFirebaseContext } from "../../../providers/firebase";

interface IOrganisationTabProps {
  account: IAccount;
  user: IUser;
}
export const OrganisationTab = ({ account }: IOrganisationTabProps) => {
  const { createSessionEditCard, isCreatingEditCardSession, state } =
    useFirebaseContext();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          justifyContent: "center",
          padding: 24,
          gap: 8,
        }}
      >
        <div
          style={{
            flex: 1,
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 20,
            height: "100%",
          }}
        >
          <div>{account.name}</div>
        </div>
        {account.subscriptionId && (
          <Button
            disabled={isCreatingEditCardSession}
            variant="text"
            color="primary"
            onClick={createSessionEditCard}
            size="small"
            style={{ position: "absolute", right: 4, bottom: 0 }}
          >
            Change credit card
          </Button>
        )}
        {state.enterpriseSubscription &&
          state.enterpriseSubscription.stripeCustomerId && (
            <Button
              disabled={isCreatingEditCardSession}
              variant="text"
              color="primary"
              onClick={createSessionEditCard}
              size="small"
              style={{ position: "absolute", right: 4, bottom: 0 }}
            >
              Change credit card
            </Button>
          )}
        {account.enterpriseSubscriptionId && state.user?.isAdmin && (
          <AdminChangeStripeCustomerID />
        )}
      </div>
      <UsersPanel account={account} />
    </div>
  );
};

const AdminChangeStripeCustomerID = () => {
  const { state, setEnterpriseCustomerId } = useFirebaseContext();
  const [stripeCustomerId, setStripeCustomerId] = useState(
    state.enterpriseSubscription?.stripeCustomerId ?? ""
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingStripeCustomer, setIsLoadingStripeCustomer] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const checkStripeCustomerExists = async (stripeCustomerId: string) => {
    setIsLoadingStripeCustomer(true);

    if (!state.enterpriseSubscription) return;
    try {
      const { error } = await setEnterpriseCustomerId(
        stripeCustomerId,
        state.enterpriseSubscription.id
      );

      if (error) {
        enqueueSnackbar(
          "Something went wrong, maybe you should double check that the Customer ID is correct",
          {
            variant: "error",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
            autoHideDuration: 10000,
          }
        );
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingStripeCustomer(false);
      setDialogIsOpen(false);
    }
  };

  if (!state.enterpriseSubscription) return <div />;

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        onClick={() => setDialogIsOpen(true)}
        endIcon={<Edit />}
        style={{
          color: "white",
          backgroundColor: "black",
          position: "absolute",
          right: 4,
          top: 4,
        }}
      >
        {state.enterpriseSubscription.stripeCustomerId ??
          "No stripe subscription"}
      </Button>

      <Dialog open={dialogIsOpen} onClose={() => setDialogIsOpen(false)}>
        <DialogTitle id="form-dialog-title">
          Edit the Stripe customer ID
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              This dialog allows you to pair an enterprise subscription with a
              "stripe customer". You can find the stripe customers in the Stripe
              Dashboard.
            </p>
            <p>
              Pairing a stripe customer with an enterprise subscription allows
              the enterprise user to manage their credit card in our Stripe
              account.
            </p>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Input
                value={stripeCustomerId}
                placeholder="Stripe Customer ID (eg cus_L2lmeGa7Yu9qHp)"
                onChange={(e) => setStripeCustomerId(e.target.value)}
                style={{ width: 420 }}
                disabled={isLoadingStripeCustomer}
              />
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  checkStripeCustomerExists(stripeCustomerId);
                }}
                disabled={isLoadingStripeCustomer}
              >
                Update
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
