/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import Stripe from "stripe";

export interface IStripeState {
  subscriptions: Stripe.Subscription[];
}

const StripeContext = createContext<IStripeState>({
  subscriptions: [],
});

export const StripeProvider = ({ children }: { children?: any }) => {
  const [subscriptions, setSubscriptions] = useState<Stripe.Subscription[]>([]);

  return (
    <StripeContext.Provider
      value={{
        subscriptions,
      }}
      children={children}
    />
  );
};

export const StripeProviderContext = () => useContext(StripeContext);
