import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { DialogProvider } from "use-material-ui-dialog";
import { useWindowSize } from "usehooks-ts";

import { MetoceanAPIProvider } from "./providers/metoceanapi";
import { MiscProvider } from "./providers/Misc";
import { StripeProvider } from "./providers/stripe";
import { Dashboard } from "./views/Dashboard";
import "./App.css";
import { SignIn } from "./views/signin";
import { FirebaseProvider } from "./providers/firebase";
import { EnterpriseModal } from "./views/Dashboard/components/EnterpriseModal";
import { useEffect } from "react";
import {
  EnquiryModalProvider,
  useEnquiryContext,
} from "./providers/EnquiryModal";

const TheRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/auth" element={<SignIn />} />
      <Route path="/" element={<div />} />
    </Routes>
  );
};

const App = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { width, height } = useWindowSize();
  const { openEnterpriseModal } = useEnquiryContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.has("enquiry")) {
      openEnterpriseModal();
      searchParams.delete("enquiry");
      setSearchParams(searchParams);
      navigate("/auth");
    }
  }, [searchParams]);

  return (
    <SnackbarProvider maxSnack={3}>
      <StripeProvider>
        <MetoceanAPIProvider>
          <FirebaseProvider>
            <MiscProvider>
              <ConfirmProvider>
                <DialogProvider>
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: width,
                      height: height,
                      zIndex: -1,
                      backgroundImage: "linear-gradient(#1D4B87, #407BB7)",
                    }}
                  />
                  <TheRoutes />
                  <EnterpriseModal />
                </DialogProvider>
              </ConfirmProvider>
            </MiscProvider>
          </FirebaseProvider>
        </MetoceanAPIProvider>
      </StripeProvider>
    </SnackbarProvider>
  );
};

function AppWrap() {
  return (
    <Router>
      <EnquiryModalProvider>
        <App />
      </EnquiryModalProvider>
    </Router>
  );
}

export default AppWrap;
