import React from "react";
import { IconButton } from "@material-ui/core";
import { Delete, FileCopy } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import styled from "styled-components";

const Code = styled.code`
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
  }
`;

interface ItemProps {
  onDelete: () => void;
  label: string;
}
export const Item = ({ onDelete, label }: ItemProps) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: 6,
        height: 48,
        borderRadius: 4,
        backgroundColor: "#e5e9f6",
      }}
    >
      <div
        style={{
          borderRight: "1px solid rgb(106, 102, 118)",
          width: 48,
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
        }}
      >
        <IconButton edge="end" aria-label="delete" onClick={onDelete}>
          <Delete />
        </IconButton>
      </div>
      <Code
        style={{
          flexGrow: 1,
          overflowY: "hidden",
          overflowX: "scroll",
          whiteSpace: "nowrap",
          color: "#16161d",
          fontWeight: "bold",
          height: "100%",
          lineHeight: 3,
          paddingLeft: 15,
        }}
      >
        {label}
      </Code>
      <div
        style={{
          borderLeft: "1px solid rgb(106, 102, 118)",
          width: 48,
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        <IconButton
          style={{ height: "100%" }}
          edge="end"
          aria-label="delete"
          onClick={() => {
            navigator.clipboard.writeText(label);
            enqueueSnackbar("Copied to clipboard!", {
              variant: "success",
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
            });
          }}
        >
          <FileCopy style={{ fill: "#7599FF" }} />
        </IconButton>
      </div>
    </div>
  );
};
