import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { IUser } from "../../../../../types";
import { useFirebaseContext } from "../../../providers/firebase";

const gap = 20;
export const Terms = ({
  open,
  user,
  onClose,
}: {
  open: boolean;
  user: IUser;
  onClose: () => void;
}) => {
  const { setUser } = useFirebaseContext();
  if (!user) return <div />;

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      className="test"
    >
      <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>
        MetOcean Solutions API Terms and Conditions
      </DialogTitle>
      <DialogContent>
        Introduction
        <br />
        <br />
        Thank you for your interest in our API. We aim to provide access to the
        highest quality weather data available and are excited to see what you
        can do with it to enhance your own products and services. Forecast API
        is developed by MetOcean Solutions, a division of the Meteorological
        Service of New Zealand Limited.
        <br />
        <br />
        These API terms and conditions (<b>API T&Cs</b>) apply to your use of
        Data obtained through the MetOcean API Service (<b>API</b>) and form
        part of MetOcean’s general terms and conditions titled{" "}
        <a href="https://about.metservice.com/our-company/about-this-site/terms-of-use/">
          Terms of Use
        </a>
        . If there is any inconsistency, these API T&Cs prevail to the extent of
        that inconsistency.
        <br />
        <br />
        By clicking <i>Accept Terms</i> (or a similar box or button) or when you
        access our API, you are agreeing to comply with these API T&Cs (as
        amended by us from time to time) and must continue to comply each time
        you access or use our API, so please take the time to review these. If
        you are agreeing to these API T&Cs on behalf of another person,
        organisation or other entity, you confirm that you are authorised to,
        and do in fact, agree to these API T&Cs on behalf of that person,
        organisation or other entity. If you are a commercial customer, you must
        also comply with our general terms and conditions. If you subcontract
        the development of your application, you will procure that any third
        party subcontractor complies with these API T&Cs.
        <br />
        <br />
        If you do not accept these API T&Cs, you are not authorised to access
        the APIs, and you must immediately stop your access and use.
        <br />
        <div>
          <div style={{ paddingLeft: gap }}>
            <br />
            <b>1. Definitions</b>
            <br />
            <br />
            <div style={{ paddingLeft: gap }}>
              <div>
                <b>1.1</b> Capitalised words and phrases used in these API T&Cs
                have the meaning given in the Terms of Use.
              </div>
              <br />
              <div>
                <b>1.2</b> Including and similar words do not imply any limit.
              </div>
              <br />
              <div>
                <b>1.3 </b>
                The following phrases have the stated meaning:
                <div style={{ paddingLeft: gap }}>
                  a. <b>Application</b> means the application for which you
                  require API access.
                  <br />
                  <br />
                  b. <b>Approved Purpose</b> means the purpose specified in your
                  Plan, or where no purpose is specified, in accordance with the
                  terms of this agreement.
                  <br />
                  <br />
                  c. <b>Attribution</b> means the following italicised text:{" "}
                  <i>
                    this data was provided by Meteorological Service of New
                    Zealand Limited.
                  </i>
                  <br />
                  <br />
                  d. <b>Data</b> means any information and/or content which is
                  made available by MetOcean via the API and includes, where
                  applicable, Open Access Data.
                  <br />
                  <br />
                  e. <b>End</b> User means an end user of your Application that
                  accesses or makes use of the API or Data.
                  <br />
                  <br />
                  f. <b>Fees</b> means the fees set out on the Plans and Pricing
                  Page, as amended by us from time to time.
                  <br />
                  <br />
                  g. <b>Intellectual Property Rights</b> includes copyright and
                  all rights existing anywhere in the world conferred under
                  statute, common law or equity relating to inventions
                  (including patents), registered and unregistered trademarks
                  and designs, circuit layouts, data and databases, confidential
                  information, know-how, and all other rights resulting from
                  intellectual activity. <b>Intellectual Property</b> has a
                  consistent meaning and includes any enhancement or
                  modification of the Intellectual Property.
                  <br />
                  <br />
                  h. <b>Permitted User</b> means any of your personnel who have
                  a need to know, or access Data through the API, who are
                  authorised to access and use the API on your behalf in
                  accordance with clause 2.3.
                  <br />
                  <br />
                  i. <b>Personal Information</b> has the definition given to it
                  in the Privacy Act 2020.
                  <br />
                  <br />
                  j. <b>Plan</b> means the plan you agree to upon signing up or
                  switch to after signing up, as set out on the Plans and
                  Pricing Page.
                  <br />
                  <br />
                  k. <b>Plans and Pricing Page</b> means the page set out at
                  https://www.metocean.co.nz/weather-forecast-api, as amended by
                  us from time to time.
                  <br />
                  <br />
                  l. <b>Systems</b> means network, operating system and the
                  software of your web servers, databases and computer systems.
                  <br />
                  <br />
                  m. <b>Usage Limits</b> means the usage limits set out in your
                  Plan, as amended by us from time to time as outlined on the
                  Limits page of the supporting documentation.
                  <br />
                  <br />
                  n. <b>We, us, our </b>
                  or <b>MetOcean</b> means MetOcean Solutions, a division of
                  Meteorological Services of New Zealand Limited.
                  <br />
                  <br />
                  o. <b>You</b> or <b>your</b> means you as the user of our API
                  or, if applicable, both you and the other persons,
                  organisation or other entity on whose behalf you are acting.
                </div>
              </div>
            </div>
          </div>
          <br />
          <div style={{ paddingLeft: gap }}>
            <b>2. API Use and Restrictions</b>
            <br />
            <br />
            <div style={{ paddingLeft: gap }}>
              <div>
                <b>2.1.</b> You must register as a consumer of our API, when you
                register you must provide the necessary details and agree to
                receive occasional communication from us relating to the
                provision of the API and Data, including, but not limited to,
                security updates and notifications of outages. When you
                register, you must choose a Plan.
                <br />
                <br />
                <b>2.2.</b> If you are issued access to the API upon
                registration as a consumer of the API, you are granted a
                non-sublicensable, non-exclusive, non-transferable licence to
                use the API in accordance with these API T&Cs solely for the
                Approved Purpose. You are responsible for the security and
                confidentiality of your access credentials to access the Data.
                We may require you at any time to provide more information about
                the purpose of your Application.
                <br />
                <br />
                <b>2.3.</b> Without limiting the remainder of this clause 2:
                <br />
                <br />
                <div style={{ paddingLeft: gap }}>
                  a. You must access and use the API in accordance with your
                  Plan, including its Usage Limits;
                  <br />
                  <br />
                  b. no individual other than a Permitted User may access or use
                  the API;
                  <br />
                  <br />
                  c. you may authorise any member of your personnel to be a
                  Permitted User and you must provide us with the Permitted
                  User’s name and other information that we may reasonably
                  require in relation to the Permitted User on our request;
                  <br />
                  <br />
                  d. you must procure each Permitted User’s compliance with the
                  requirements set out under this clause 2 and any other
                  reasonable condition notified by us to you;
                  <br />
                  <br />
                  e. a breach of any term or condition of these API T&Cs by your
                  personnel (including, to avoid doubt, a Permitted User) is
                  deemed to be a breach of the API T&Cs by you;
                  <br />
                  <br />
                  f. you acknowledge that you will ensure the compliance of
                  Permitted Users who interact with or use the API or any Data
                  on your behalf and agree that your organisation will be
                  responsible for the acts and omissions of Permitted Users who
                  interact with or use any API or Data. References to “you” and
                  “your” is a reference to both you in your personal capacity
                  and to your organisation; and
                  <br />
                  <br />
                  g. you must revoke access to the API for a Permitted User when
                  personnel change.
                </div>
                <br />
                <b>2.4.</b> You must ensure your End Users’ compliance with
                applicable law, regulation, and the API T&Cs.
                <br />
                <br />
                <b>2.5.</b> You acknowledge that you obtain no right to the Data
                beyond that which is contemplated by these API T&Cs.
                <br />
                <br />
                <b>2.6.</b> When using our API, you must follow security best
                practices for your Application and technologies, and be familiar
                with and take all reasonable steps to mitigate internet security
                risks.
                <br />
                <br />
                <b>2.7.</b> Your use of the API must be fair, reasonable and/or
                not excessive (as determined by us). Excessive usage is outlined
                in the Limits page of the supporting documentation and is
                subject to change as required. Our determination may include
                considering average or estimated typical usage of the API or
                unfair loading on the MetOcean network or any underlying system.
                Without limiting the remainder of this clause, you must comply
                with any Usage Limits and your Plan. The Usage Limits are set
                and granted at our discretion, we may raise or lower the
                restriction limits at any time without notice or liability to
                you.
                <br />
                <br />
                <b>2.8.</b> When you use and/or display the Data, you can
                include the relevant Attribution. The Attribution can be
                immediately above, below or beside the Data. MetOcean grants to
                you a non-transferable, non-sublicenseable, non-exclusive,
                revocable, license while you use our API in accordance with the
                API T&Cs to display MetOcean brand features for the purpose of
                promoting or advertising that you use the API. You agree that we
                have sole discretion to determine whether your Attribution and
                use of MetOcean’s brand features are satisfactory.
                <br />
                <br />
                <b>2.9.</b> In addition to providing appropriate Attribution in
                accordance with clause 2.8, you must not use or display the Data
                or make any statement regarding your use of an API:
                <br />
                <br />
                <div style={{ paddingLeft: gap }}>
                  a. in a way that:
                  <br />
                  <br />
                  <div style={{ paddingLeft: gap }}>
                    i. states or implies partnership with or sponsorship by
                    MetOcean, or that MetOcean endorses you, your business, or
                    any product or service without our prior written consent; or
                    <br />
                    <br />
                    ii. is misleading or deceptive or is likely to mislead or
                    deceive;
                  </div>
                  <br />
                  <br />
                  b. through any means (including a website), in any way, for
                  any purpose, or in conjunction with items (e.g., advertising),
                  that is objectionable (in our sole opinion); and
                  <br />
                  <br />
                  c. where that Data is not current.
                </div>
                <br />
                <b>2.10.</b> Your Systems must be properly configured to
                securely operate your Application and process Data.
                <br />
                <br />
                <b>2.11.</b> If you use the API to enhance a service offered to
                an End User, you must ensure you operate that service and your
                Application:
                <br />
                <br />
                <div style={{ paddingLeft: gap }}>
                  a. lawfully and ethically; and
                  <br />
                  <br />
                  b. in a manner that does not damage or adversely affect our
                  reputation or the reputation of the API.
                </div>
                <br />
                <br />
                <b>2.12.</b> Without limiting clauses <b>2.1</b> to <b>2.11</b>{" "}
                above, you must:
                <br />
                <br />
                <div style={{ paddingLeft: gap }}>
                  a. take all reasonable steps to keep Data secure from
                  unauthorised use or access, including not sharing your access
                  credentials or allowing others to use them;
                  <br />
                  <br />
                  b. follow security best practices for your Application, and be
                  familiar with and take all reasonable steps to mitigate
                  internet security risks;
                  <br />
                  <br />
                  c. notify us as soon as you become aware of any breach of
                  security or unauthorised use of your access credentials (or
                  unauthorised access to the API through your Systems);
                  <br />
                  <br />
                  d. comply with any guidelines or directions advised from time
                  to time by MetOcean on the use of the API; and
                  <br />
                  <br />
                  e. comply with all applicable laws.
                </div>
                <br />
                <br />
                <b>2.13.</b> You must not use or access the API:
                <br />
                <br />
                <div style={{ paddingLeft: gap }}>
                  a. in any way which is misleading or deceptive (including
                  being likely to mislead or deceive), infringes another
                  person’s rights (including Intellectual Property Rights or
                  privacy rights);
                  <br />
                  <br />
                  b. to copy, scrape or store any Data;
                  <br />
                  <br />
                  c. to modify or reverse engineer the API;
                </div>
              </div>
            </div>
          </div>
          <br />
          <div style={{ paddingLeft: gap }}>
            <b>3. User Information and Data</b>
            <br />
            <br />
            <div style={{ paddingLeft: gap }}>
              <b>3.1.</b> In dealing with End Users’ Personal Information, you
              must comply with the Privacy Act 2020. By using our API, we may
              use submitted information in accordance with our privacy policy.
              <br />
              <br />
              <b>3.2.</b> You must follow our instructions in relation to Data
              or Personal Information.
              <br />
              <br />
              <b>3.3.</b> You agree to delete all Data:
              <br />
              <br />
              <div style={{ paddingLeft: gap }}>
                a. when your access is terminated in accordance with clause 6;
                <br />
                <br />
                b. when it is no longer required for your Application’s Approved
                Purpose; or
                <br />
                <br />
                c. as we might otherwise direct you.
                <br />
                <br />
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: gap }}>
            <b>4. Intellectual Property</b>
            <br />
            <br />
            <div style={{ paddingLeft: gap }}>
              <b>4.1.</b> We (and our licensors) own all proprietary and
              Intellectual Property Rights in the API and the Data (including
              all information, data, text, graphics, artwork, photographs,
              logos, icons, sound recordings, videos and <i>look and feel</i>).
              You must not contest or dispute that ownership, or the validity of
              those Intellectual Property Rights.
              <br />
              <br />
              <b>4.2.</b> If you provide us with ideas, comments or suggestions
              relating to the API or Data (together <b>feedback</b>):
              <br />
              <br />
              <div style={{ paddingLeft: gap }}>
                a. all Intellectual Property Rights in that feedback, and
                anything created as a result of that feedback (including new
                material, enhancements, modifications or derivative works), are
                owned solely by us; and
                <br />
                <br />
                b. we may use or disclose the feedback for any purpose.
              </div>
              <br />
            </div>
          </div>
          <div style={{ paddingLeft: gap }}>
            <b>5. Fees</b>
            <br />
            <br />
            <div style={{ paddingLeft: gap }}>
              Your use of and access to the API is subject to you paying all
              applicable Fees (if any), and complying at all times with the API
              T&Cs.
            </div>
          </div>
          <br />
          <div style={{ paddingLeft: gap }}>
            <b>6. Termination</b>
            <br />
            <br />
            <div style={{ paddingLeft: gap }}>
              <b>6.1.</b> You may stop using our API at any time with or without
              notice. Upon termination or discontinuation of your access to an
              API you must immediately stop all use of the API, cease all use of
              any MetOcean brand features and delete any cached or stored
              content.
              <br />
              <br />
              <b>6.2.</b> At any time, MetOcean may:
              <br />
              <br />
              <div style={{ paddingLeft: gap }}>
                a. discontinue or change an API, including making
                backwards-incompatible changes;
                <br />
                <br />
                b. modify, add, replace, or upgrade any API; or
                <br />
                <br />
                c. impose additional eligibility requirements or restrictions to
                access our API (<b>Modification</b>).
              </div>
              <br />
              <b>6.3.</b> MetOcean will use reasonable efforts to notify you of
              any changes. Continued use of the API will be deemed to be your
              acceptance of any Modification.
              <br />
              <br />
              <b>6.4.</b> If we determine that your Application is insecure or
              may pose a security risk MetOcean may, at its sole discretion,
              disable your access to the API or any portion or feature of your
              access thereto with or without prior warning to you.
              <br />
              <br />
              <b>6.5.</b> Access to the API is at our discretion. We are able to
              permit, restrict or exclude your use of the API in any way,
              regardless of whether you are in breach of the API T&Cs or not.
            </div>
            <br />
          </div>
          <div style={{ paddingLeft: gap }}>
            <b>7. Liability and Indemnity</b>
            <br />
            <br />
            <div style={{ paddingLeft: gap }}>
              <b>7.1.</b> You acknowledge that some of our API involve the use
              of our Data and Data belonging to third parties. MetOcean gives no
              warranties or representation about the API or the Data and does
              not promise that the API or the Data will:
              <br />
              <br />
              <div style={{ paddingLeft: gap }}>
                a. be available, accurate or current;
                <br />
                <br />
                b. meet your requirements or be suitable for a particular
                purpose; or
                <br />
                <br />
                c. be free or viruses or other harmful code, uninterrupted or
                error-free.
              </div>
              <br />
              <b>7.2.</b> You acknowledge the uncertainty inherent in forecasts
              and predictions and agree that, to the maximum extent permitted by
              law, MetOcean shall not be liable for any direct or indirect loss,
              damage, or expense incurred by you, a Customer, or a third party
              as a result of any forecast or prediction provided being or
              purportedly being wholly or partially incorrect. You indemnify
              MetOcean from and against any claim, action, or proceeding made
              by, or any loss, cost or expense payable to, any third party
              including a Customer or other end-user arising from, or in
              connection with, your or any Customer’s use or distribution of, or
              reliance on, the API.
              <br />
              <br />
              <b>7.3.</b> To the maximum extent permitted by law:
              <br />
              <br />
              <div style={{ paddingLeft: gap }}>
                a. you access and use the API at your own risk; and
                <br />
                <br />
                b. MetOcean is not liable or responsible to you or any other
                person for any claim, damage, loss, liability, and cost under or
                in connection with these API T&Cs, the API, or your (or your End
                Users’) access and use of (or inability to access or use) the
                API. This exclusion applies regardless of whether MetOcean’s
                liability or responsibility arises in contract, tort (including
                negligence), equity, breach of statutory duty, or otherwise.
              </div>
              <br />
              <br />
              <b>7.4.</b> To the extent clause 7.2 doesn’t apply, and if our
              liability to you or any person under these API T&Cs, or in
              connection with the API cannot be excluded, it is limited to
              NZ$100.00.
              <br />
              <br />
              <b>7.5.</b> MetOcean is not liable under or in connection with
              these API T&Cs or in connection with the API for any:
              <br />
              <br />
              <div style={{ paddingLeft: gap }}>
                a. loss of profit, revenue, savings, business, data and/or
                goodwill; or
                <br />
                <br />
                b. consequential, indirect, incidental or special damage or loss
                of any kind.
              </div>
              <br />
              <br />
              <b>7.6.</b> You agree and represent that you are using the API,
              and accepting these API T&Cs for the purposes of trade. You agree
              that, to the maximum extent permitted by law, the Consumer
              Guarantees Act 1993 and any other applicable consumer protection
              legislation does not apply to the supply of the API and that it is
              fair and reasonable that the parties are bound by this clause 7.5.
              <br />
              <br />
              <b>7.7.</b> You agree to release, indemnify and keep MetOcean
              indemnified from and against all actions, claims, costs (including
              legal costs and expenses), losses, proceedings, damages,
              liabilities, or demands suffered or incurred by us to any person
              arising out of or in connection with:
              <br />
              <br />
              <div style={{ paddingLeft: gap }}>
                a. your failure or an End User’s failure to comply with these
                API T&Cs;
                <br />
                <br />
                b. your misuse or an End User’s misuse of the API; and
                <br />
                <br />
                c. your Application, or any use of your Application, including
                your relationships or interactions with any End User.
              </div>
            </div>
            <br />
          </div>
          <div style={{ paddingLeft: gap }}>
            <b>8. General</b>
            <br />
            <br />
            <div style={{ paddingLeft: gap }}>
              <b>8.1.</b> We will use reasonable efforts to provide the support
              services set out in your Plan.
              <br />
              <br />
              <b>8.2.</b> We may provide you with support for the API at our
              sole discretion and we may stop providing support to you at any
              time without notice or liability to you. We are not obliged to
              provide any training, support, or technical assistance for the
              Application, the Data or the API directly to your End Users and we
              have no obligation to fix or respond to errors you may encounter.
              <br />
              <br />
              <b>8.3.</b> You acknowledge that MetOcean may monitor any API
              activity from its own systems for the purpose of ensuring quality,
              improving MetOcean products and services, and ensuring compliance
              with these API T&Cs.
              <br />
              <br />
              <b>8.4.</b> We may publicly refer to you, orally or in writing, as
              a user of our API and/or user of the Data. Unless you inform us
              otherwise, we may also publish your name and/or logo in
              promotional materials without additional consent or notice to you.
              <br />
              <br />
              <b>8.5.</b> These API T&Cs are governed by the laws of New
              Zealand. You submit to the non-exclusive jurisdiction of the
              Courts of New Zealand.
              <br />
              <br />
              <b>8.6.</b> If any part or provision of these API T&Cs is or
              becomes illegal, unenforceable, or invalid, that part or provision
              is deemed to be modified to the extent required to remedy the
              illegality, unenforceability, or invalidity. If modification is
              not possible, the part or provision must be treated for all
              purposes as severed from these API T&Cs. The remainder of these
              API T&Cs will be binding on you.
              <br />
              <br />
              <b>8.7.</b> We will continue to update these API T&Cs as our API
              evolves and develops. You or your Application’s continued use of
              the API will constitute your acceptance of the updated API T&Cs.
            </div>
          </div>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={user?.hasAcceptedTerms}
              onChange={(e) => {
                if (!user) {
                  console.log("tried to change terms but no user");
                  return;
                }
                setUser({ ...user, hasAcceptedTerms: e.target.checked });
              }}
              color="primary"
            />
          }
          label="Accept terms"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
