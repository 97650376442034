import { useFirebaseContext, tiers } from "../../../providers/firebase";
import { IPlan } from "../../../../../types";
import { ButtonBase } from "@material-ui/core";
import { useMetoceanAPIContext } from "../../../providers/metoceanapi";
import { useMiscContext } from "../../../providers/Misc";

const getAccessLevel = (plan: IPlan) => {
  return Number(plan.metadata.accessLevel);
};

export const DatasetsPreview = () => {
  const { summaries } = useMetoceanAPIContext();

  const {
    state: { plans, user },
  } = useFirebaseContext();

  const { openModelSummary } = useMiscContext();

  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          height: 48,
          backgroundColor: "#eef1f6",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "1.2em",
        }}
      >
        Datasets
      </div>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: -1 }}>
        {tiers.map((tier, i, tiers) => {
          const plan = plans.find((p) => p.stripe_metadata_tier === tier);
          if (!plan) return <div key={i} />;
          const lowerTier = tiers[i - 1];
          const lowerPlan = plans.find(
            (p) => p.stripe_metadata_tier === lowerTier
          );
          const previousAccessLevel = lowerPlan ? getAccessLevel(lowerPlan) : 0;
          const accessLevel = getAccessLevel(plan);

          return (
            <div
              key={i}
              style={{
                flex: 1,
                flexGrow: 1,
                width: `${1 / plans.length}%`,
                display: "flex",
                flexDirection: "column",
                borderRight: i === tiers.length - 1 ? "" : "2px solid #f0f4fd",
                borderTop: "2px solid #f0f4fd",
              }}
            >
              <div
                style={{
                  borderBottom: "2px solid #f0f4fd",
                  paddingBottom: 17,
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 20,
                    paddingTop: 10,
                  }}
                >
                  {plan ? plan.stripe_metadata_title : "-"}
                </div>
                <div style={{ fontSize: "0.7em", color: "#444" }}>
                  {lowerPlan && (
                    <span>
                      All {lowerPlan.stripe_metadata_title} datasets and...
                    </span>
                  )}
                  {!lowerPlan && <span>Free</span>}
                </div>
              </div>
              {(plan.stripe_metadata_tier !== "enterprise" ||
                user?.isAdmin) && (
                <div>
                  {summaries
                    .filter(
                      (s) =>
                        s.accessLevel <= accessLevel &&
                        s.accessLevel > previousAccessLevel
                    )
                    .map((modelSummary, i, { length }) => {
                      return (
                        <div
                          key={i}
                          style={{
                            borderBottom: "2px solid #f0f4fd",
                            overflowWrap: "break-word",
                            verticalAlign: "center",
                          }}
                          className="model-summary"
                        >
                          <ButtonBase
                            style={{
                              width: "100%",
                              padding: 5,
                              height: 70,
                              paddingLeft: 20,
                              paddingRight: 20,
                            }}
                            onClick={() => openModelSummary(modelSummary)}
                          >
                            {modelSummary.name}
                          </ButtonBase>
                        </div>
                      );
                    })}
                </div>
              )}
              {plan.stripe_metadata_tier === "enterprise" && !user?.isAdmin && (
                <div
                  style={{
                    padding: 18,
                    fontSize: "0.8em",
                  }}
                >
                  Custom datasets available on your request.
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
