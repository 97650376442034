import { useState, useEffect } from "react";
import { useFirebaseContext, tiers } from "../../../providers/firebase";
import { IPlan } from "../../../../../types";
import { Button } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";

import { useMiscContext } from "../../../providers/Misc";
import { getIncludedApiUnits, getPrices } from "../magic";
import { useEnquiryContext } from "../../../providers/EnquiryModal";

const renderUpto = (plan: IPlan) => {
  const upto = getIncludedApiUnits(plan);
  if (upto === null) return "-";
  return (upto * 100).toLocaleString();
};

const Row = ({ left, right }: { left: string; right: string }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: 6,
        alignItems: "flex-end",
      }}
    >
      <div style={{ fontWeight: "bold", fontSize: 16 }}>{left}</div>
      <div>{right}</div>
    </div>
  );
};

const renderMonthlyPrice = (plan: IPlan) => {
  const { monthly } = getPrices(plan);
  if (!monthly) return <span>-</span>;

  const flatAmount =
    monthly.unit_amount === null ? 0 : parseFloat(monthly.unit_amount);

  return (
    <div>
      <Row
        left={"$" + (flatAmount / 100).toString()}
        right={monthly.currency.toUpperCase()}
      />
      <div style={{ fontSize: 14 }}>per month</div>
    </div>
  );
};

const getUnitCost = (plan: IPlan) => {
  if (plan.metadata.tier === "starter") return "-";

  const { tiered } = getPrices(plan);
  if (!tiered) return "-";
  if (!tiered.tiers) return "-";
  const tier = tiered.tiers[tiered.tiers.length - 1];
  return (parseFloat(tier.unit_amount_decimal) / 100).toFixed(2);
};

const defaultButtonStyle = {
  height: 30,
  width: "100%",
};

const TheButton = ({
  plan,
  tryToSubscribe,
}: {
  plan: IPlan;
  tryToSubscribe: (planId: string) => void;
}) => {
  const confirm = useConfirm();
  const { openEnterpriseModal } = useEnquiryContext();
  const {
    cancelSubscription,
    state,
    isCreatingSession,
    isCancellingSubscription,
  } = useFirebaseContext();

  const account = state.accounts.find((a) => a.id === state.user?.usingAccount);

  const { subscription } = state;
  const isSubscribed = subscription?.productId === plan.id;

  const defaultButtonProps = {
    style: { ...defaultButtonStyle },
    disabled: isCreatingSession || isCancellingSubscription,
  };

  const enterpriseMessage = () => {
    confirm({
      dialogProps: {
        open: true,
        maxWidth: "xs",
      },
      title: null,
      description: (
        <div style={{ textAlign: "center" }}>
          <div>You have an enterprise subscription</div>
        </div>
      ),
      confirmationText: "Ok",
      cancellationText: null,
    });
  };
  if (plan.stripe_metadata_tier === "enterprise")
    return (
      <Button
        variant={"contained"}
        color={"primary"}
        {...defaultButtonProps}
        onClick={() => {
          if (!!account?.enterpriseSubscriptionId) {
            enterpriseMessage();
            return;
          }
          openEnterpriseModal();
        }}
      >
        Contact
      </Button>
    );

  if (!isSubscribed)
    return (
      <Button
        variant={"contained"}
        color={"primary"}
        {...defaultButtonProps}
        onClick={() => {
          console.log("test");
          console.log(state.user);
          if (!state.user) {
            console.log("user tried to subscribe but does not exist");
            return;
          }
          if (!!account?.enterpriseSubscriptionId) {
            enterpriseMessage();
            return;
          }
          console.log(plan.id);
          tryToSubscribe(plan.id);
        }}
      >
        Subscribe
      </Button>
    );

  if (isSubscribed)
    return (
      <Button
        variant={"contained"}
        color={"default"}
        {...defaultButtonProps}
        style={{
          ...defaultButtonProps.style,
          backgroundColor: "#fff",
          color: "#587be7",
          border: "1px solid #a0b5f2",
        }}
        className={"unsubscribe-button"}
        onClick={() => {
          if (!!account?.enterpriseSubscriptionId) {
            enterpriseMessage();
            return;
          }
          confirm({
            dialogProps: {
              open: true,
              maxWidth: "xs",
            },
            title: null,
            description: (
              <div style={{ textAlign: "center" }}>
                <div>Are you sure that you want to unsubscribe?</div>
                <div>
                  <b>This will remove access from your apikeys</b>
                </div>
              </div>
            ),
            confirmationText: "Unsubscribe",
            cancellationText: "Back",
            confirmationButtonProps: {
              color: "secondary",
            },
          }).then(() => {
            if (!subscription) return;
            return cancelSubscription(subscription.id);
          });
        }}
      >
        Unsubscribe
      </Button>
    );

  return <div />;
};

// This component lets the developer subscribe to the test product
const TestProductSection = () => {
  const { state, beginSubscription, cancelSubscription } = useFirebaseContext();

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: -140,
        backgroundColor: "white",
        borderRadius: 4,
        padding: 12,
      }}
    >
      {state.subscription && (
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            if (!state.subscription) return;
            cancelSubscription(state.subscription.id);
          }}
        >
          Unsubscribe
        </Button>
      )}
      {!state.subscription && (
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            console.log("onclick");
            if (!state.user) {
              console.log("user tried to subscribe but does not exist");
              return;
            }
            beginSubscription("prod_LFJpP4wnW5OgPF");
          }}
        >
          Subscribe
        </Button>
      )}
    </div>
  );
};

export const PlansTab = () => {
  const {
    state: { plans, user, subscription },
    beginSubscription,
  } = useFirebaseContext();
  const { openTerms, termsAreOpen } = useMiscContext();

  const [isTryingToSubscribeTo, setIsTryingToSubscribeTo] = useState<
    null | string
  >(null);

  useEffect(() => {
    if (!user) return;
    if (
      termsAreOpen === false &&
      user.hasAcceptedTerms &&
      isTryingToSubscribeTo
    ) {
      beginSubscription(isTryingToSubscribeTo);
      setIsTryingToSubscribeTo(null);
    }
  }, [termsAreOpen]);

  const subscribedToProduct = subscription?.productId;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        paddingTop: 2,
        position: "relative",
      }}
    >
      {
        false && (
          <TestProductSection />
        ) /* use this to subscribe to the test product */
      }

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          backgroundColor: "#f0f4fd",
          borderRadius: 4,
        }}
      >
        {tiers.map((tier, i, tiers) => {
          const plan = plans.find((p) => p.stripe_metadata_tier === tier);
          const isSubscribed = subscribedToProduct === plan?.id;

          return (
            <div
              key={i}
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: 440,
                flexGrow: 1,
                backgroundColor: isSubscribed ? "#f0f4fd" : "#fff",
                borderRadius: 4,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: 12,
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    paddingTop: 10,
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    {plan?.stripe_metadata_title ?? "-"}
                  </div>
                </div>
                <div style={{ height: 100 }}>
                  {plan &&
                    plan.metadata.tier !== "enterprise" &&
                    plan.metadata.tier !== "starter" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: 14,
                          gap: 32,
                        }}
                      >
                        <div>{plan ? renderMonthlyPrice(plan) : "-"}</div>
                        <div>
                          <Row left={renderUpto(plan)} right={""} />
                          <div>API Units included</div>
                        </div>
                        <div>
                          <Row left={getUnitCost(plan)} right={"USD"} />
                          <div>per 100 additional units</div>
                        </div>
                      </div>
                    )}
                  {plan && plan.metadata.tier === "starter" && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ fontWeight: "bold" }}>Free</div>
                      <div style={{ height: 10 }} />
                      <div style={{ fontSize: 12 }}>
                        <span style={{ fontWeight: "bold" }}>
                          {renderUpto(plan)}
                        </span>{" "}
                        API Units included
                      </div>
                      <div style={{ height: 10 }} />
                      <div style={{ fontSize: 14 }}>Not for commercial use</div>
                    </div>
                  )}
                  {plan && plan.metadata.tier === "enterprise" && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ fontWeight: "bold" }}>POA</div>
                      <div style={{ height: 53 }} />
                      <div style={{ fontSize: 14 }}>
                        Enterprise access to the forecast timeseries API
                        service.
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ height: 10 }} />
                {plan && (
                  <div>
                    <div style={{ padding: 10, paddingBottom: 10 }}>
                      <TheButton
                        plan={plan}
                        tryToSubscribe={(planId) => {
                          if (!user) {
                            console.log(
                              "tried to subscribe but is not logged in"
                            );
                            return;
                          }
                          console.log("before the if");
                          if (!user.hasAcceptedTerms) {
                            console.log("has not accepted terms");
                            setIsTryingToSubscribeTo(planId);
                            openTerms();
                          } else {
                            beginSubscription(planId);
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
