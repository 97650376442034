import { useState, useEffect } from "react";
import Stripe from "stripe";

import { IFirebaseState } from "../../../providers/firebase";

export const useStripeSubscription = (
  state: IFirebaseState,
  accountId?: string
) => {
  const [subscription, setSubscription] = useState<Stripe.Subscription>();

  const loadSubscription = async () => {
    if (!accountId) return;

    const { subscription } = await fetch(
      "https://us-central1-metoceanapi-auth.cloudfunctions.net/getSubscription",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          accountId,
          jwt: await state.app.auth().currentUser?.getIdToken(),
        }),
      }
    ).then((res) => res.json());

    setSubscription(subscription);
    return Promise.resolve();
  };

  useEffect(() => {
    loadSubscription();
  }, []);

  return {
    subscription,
    loadSubscription,
  };
};
