import { IPlan } from "../../../../types";

export const getPrices = (plan: IPlan) => {
  // pro
  if (plan.id === "prod_K12D19pWDJh99E") {
    return {
      monthly:
        plan.prices.find((p) => p.id === "price_1JNMS3Fb56EPkQoRGIxIdeOQ") ??
        null,
      tiered:
        plan.prices.find((p) => p.id === "price_1KYbUEFb56EPkQoRCsgLmR9N") ??
        null,
    };
  }

  // Plus
  if (plan.id === "prod_K12DN9QAI1TmZb") {
    return {
      monthly:
        plan.prices.find((p) => p.id === "price_1JNMkmFb56EPkQoRE6ddvyac") ??
        null,
      tiered:
        plan.prices.find((p) => p.id === "price_1KYb7HFb56EPkQoRTbTg5ifA") ??
        null,
    };
  }

  // Basic
  if (plan.id === "prod_K12CVGYJdh16n9") {
    return {
      monthly:
        plan.prices.find((p) => p.id === "price_1JNMmoFb56EPkQoRpppeogFb") ??
        null,
      tiered:
        plan.prices.find((p) => p.id === "price_1KYc0CFb56EPkQoRn9IExtIt") ??
        null,
    };
  }

  // Starter
  if (plan.id === "prod_K128NSSSfy6gkj") {
    return {
      monthly:
        plan.prices.find((p) => p.id === "price_1JNMo3Fb56EPkQoRCuaRfdVd") ??
        null,
      tiered:
        plan.prices.find((p) => p.id === "price_1KYdJwFb56EPkQoR8NOucger") ??
        null,
    };
  }

  // Enterprise
  if (plan.id === "prod_K12EB6ki08FPWY") {
    return { monthly: null, tiered: null };
  }

  console.log("something has gone wrong");
  return { monthly: null, tiered: null };
};

export const getAccessLevel = (plan: IPlan) => {
  return Number(plan.metadata.accessLevel);
};

export const getIncludedApiUnits = (plan: IPlan) => {
  const { tiered } = getPrices(plan);

  if (!tiered) return null;
  const tiers = tiered.tiers;
  if (tiers === undefined) return null;
  const tier = tiers[0];

  return tier.up_to;
};
