import { useState, useEffect } from "react";
import * as EmailValidator from "email-validator";

export const useEmailInput = () => {
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);

  useEffect(() => {
    setEmailIsValid(true);
  }, [email]);

  return {
    email,
    setEmail,
    checkEmail: () => {
      setEmailIsValid(EmailValidator.validate(email));
      return EmailValidator.validate(email);
    },
    emailIsValid,
  };
};
