import React, { createContext, useContext, useState } from "react";

interface IEnquiryModalContext {
  enterpriseModalIsOpen: boolean;
  openEnterpriseModal: () => void;
  closeEnterpriseModal: () => void;
}
const EnquiryModalContext = createContext<IEnquiryModalContext>({
  enterpriseModalIsOpen: false,
  openEnterpriseModal: () => null,
  closeEnterpriseModal: () => null,
});

export const EnquiryModalProvider = ({ children }: { children?: any }) => {
  const [enterpriseModalIsOpen, setEnterpriseModalIsOpen] = useState(false);

  return (
    <EnquiryModalContext.Provider
      value={{
        enterpriseModalIsOpen,
        openEnterpriseModal: () => {
          setEnterpriseModalIsOpen(true);
        },
        closeEnterpriseModal: () => {
          setEnterpriseModalIsOpen(false);
        },
      }}
    >
      {children}
    </EnquiryModalContext.Provider>
  );
};

export const useEnquiryContext = () => useContext(EnquiryModalContext);
