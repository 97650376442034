import React, { useState, useEffect } from "react";
import { TextField, IconButton } from "@material-ui/core";
import { useFirebaseContext } from "../../../providers/firebase";
import { AddCircle } from "@material-ui/icons";
import { useConfirm } from "material-ui-confirm";
import { IAccount } from "../../../../../types";
import * as EmailValidator from "email-validator";
import { useDialog } from "use-material-ui-dialog";

import { Item } from "./Item";

interface IUsersPanel {
  account: IAccount;
}
export const UsersPanel = ({ account }: IUsersPanel) => {
  const { state, addUserToAccount, removeUserFromAccount } =
    useFirebaseContext();
  const confirm = useConfirm();
  const dialog = useDialog();
  const [userToAdd, setUserToAdd] = useState("");
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);

  const [isAddingUser, setIsAddingUser] = useState(false);

  useEffect(() => {
    setEmailIsInvalid(false);
  }, [userToAdd]);

  const confirmAddUser = (email: string) => {
    if (account.userIds.includes(userToAdd)) {
      return setUserToAdd("");
    }
    if (!EmailValidator.validate(userToAdd)) {
      return setEmailIsInvalid(true);
    }
    setIsAddingUser(true);

    confirm({
      dialogProps: {
        open: true,
        maxWidth: "xs",
      },
      title: null,
      description: (
        <div style={{ textAlign: "center" }}>
          <div>Are you sure that you want add</div>
          <div>
            <b>{email}</b> to <b>{account.name}</b>
          </div>
        </div>
      ),
      confirmationText: "Yes, add this user",
      cancellationText: "Cancel",
      confirmationButtonProps: {
        color: "primary",
      },
    })
      .then(() => addUserToAccount(userToAdd, account.id))
      .then(() => setUserToAdd(""))
      .finally(() => setIsAddingUser(false));
  };

  const confirmRemoveUser = (email: string) => {
    if (account.isPersonal && account.creator === email) {
      dialog.openMessage({
        message: "You cannot be removed from your personal organisation",
        maxWidth: "xs",
        okText: "close",
      });
      return;
    }
    confirm({
      dialogProps: {
        open: true,
        maxWidth: "xs",
      },
      title: null,
      description: (
        <div style={{ textAlign: "center" }}>
          <div>Are you sure that you want to remove</div>
          <div>
            <b>{email}</b> from <b>{account.name}</b>
          </div>
        </div>
      ),
      confirmationText: "Yes, remove this user",
      cancellationText: "Cancel",
      confirmationButtonProps: {
        color: "secondary",
      },
    }).then(() => removeUserFromAccount(email, account.id));
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          height: 48,
          backgroundColor: "#eef1f6",
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          textAlign: "center",
        }}
      >
        <div style={{ flex: 1 }}>Users</div>
        <div style={{ flex: 1 }}>Add user</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 12,
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
      >
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div>
            {account.userIds.map((email, i, emails) => {
              return (
                <Item
                  key={i}
                  onDelete={() => confirmRemoveUser(email)}
                  label={
                    email === state?.user?.email ? email + " (you)" : email
                  }
                />
              );
            })}
            {isAddingUser && (
              <div
                style={{
                  color: "grey",
                  fontSize: "0.8em",
                  textAlign: "center",
                }}
              >
                ...Adding user...
              </div>
            )}
          </div>
        </div>
        <div style={{ flex: 1, textAlign: "right" }}>
          <div>
            <TextField
              style={{ width: "calc(100% - 80px)" }}
              placeholder="Put email here"
              helperText={
                emailIsInvalid
                  ? "Not a valid email"
                  : "Pressing Enter will add the user."
              }
              error={emailIsInvalid}
              value={userToAdd}
              onChange={(e) => setUserToAdd(e.target.value.toLowerCase())}
              onKeyPress={async (e) => {
                if (e.code === "Enter") return confirmAddUser(userToAdd);
              }}
              disabled={isAddingUser}
            />
            <IconButton
              disabled={state.isUpdatingApikey || isAddingUser}
              onClick={() => confirmAddUser(userToAdd)}
              color="primary"
            >
              <AddCircle style={{ paddingTop: 3, color: "#7599FF" }} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};
