import React, { useLayoutEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ReactJson from "react-json-view";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import parseDuration from "parse-duration";
import humanizeDuration from "humanize-duration";

import { IModelSummary } from "../../../../../types";

const generateGeojson = (modelSummary: IModelSummary) => {
  const { bounds } = modelSummary;
  const { north, south, east, west } = bounds;

  return {
    type: "FeatureCollection" as const,
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [west, south],
              [east, south],
              [east, north],
              [west, north],
              [west, south],
            ],
          ],
        },
        properties: modelSummary,
      },
    ],
  };
};

export const SummaryDescripter = ({
  modelSummary,
  onClose,
}: {
  modelSummary: IModelSummary;
  onClose: () => void;
}) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={modelSummary !== null}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      className="test"
    >
      <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>
        {modelSummary?.name}
      </DialogTitle>
      <DialogContent>
        {modelSummary && (
          <div style={{ fontSize: 14 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontWeight: "bold",
              }}
            >
              <div style={{ flex: 1, textAlign: "center" }}>
                Temporal resolution
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                Spatial Resolution
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                Forecast horizon
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1, textAlign: "center" }}>
                {humanizeDuration(
                  parseDuration(modelSummary.temporalResolution)
                )}
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                {modelSummary.latitudeResolution.toFixed(4)}°
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                {Math.ceil(
                  (+new Date(modelSummary.range.to) -
                    +new Date(modelSummary.range.from)) /
                    1000 /
                    60 /
                    60 /
                    24
                )}{" "}
                days
              </div>
            </div>
            <div style={{ height: 24 }} />
            <div style={{ borderRadius: 4 }}>
              <MapContainer
                center={[0, 0] as any}
                zoom={1}
                scrollWheelZoom={false}
                style={{ width: "100%", height: 360, borderRadius: 4 }}
                zoomControl={false}
              >
                <TileLayer url="https://api.maptiler.com/maps/44a3c146-e2bb-42ac-99e6-6d43720ff85b/256/{z}/{x}/{y}.png?key=oLdHhsrzCltCHWSkt3Qs" />
                <DatasetBoundary modelSummary={modelSummary} />
              </MapContainer>
            </div>
            <div style={{ height: 24 }} />
            <ReactJson src={modelSummary} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const isDatasetGlobal = (modelSummary: IModelSummary) => {
  const { bounds } = modelSummary;
  return (
    bounds.north >= 70 &&
    bounds.south <= -70 &&
    bounds.east >= 340 &&
    bounds.west <= 20
  );
};

const DatasetBoundary = ({ modelSummary }: { modelSummary: IModelSummary }) => {
  const map = useMap();
  const datasetIsGlobal = isDatasetGlobal(modelSummary);
  const [hasFinishedFlying, setHasFinishedFlying] = React.useState(false);

  const getBounds = (modelSummary: IModelSummary) => {
    const { bounds } = modelSummary;
    const { north, south, east, west } = bounds;
    return [
      [south, west],
      [north, east],
    ];
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (datasetIsGlobal) {
        map.flyToBounds([
          [-90, -180],
          [90, 180],
        ]);
        return;
      }
      //@ts-ignore
      map.flyToBounds(getBounds(modelSummary), {
        duration: 2,
      });
      setTimeout(() => {
        setHasFinishedFlying(true);
      }, 2000);
    }, 1000);
  }, []);

  return (
    <>
      {!datasetIsGlobal && hasFinishedFlying && (
        <GeoJSON
          data={generateGeojson(modelSummary)}
          style={{ color: "white", fill: false, weight: 1 }}
        />
      )}
      {datasetIsGlobal && (
        <div style={{ fontSize: 14, textAlign: "center", paddingTop: 16 }}>
          Global
        </div>
      )}
    </>
  );
};
