import React, { useState } from "react";
import {
  Paper,
  Button,
  Tabs,
  Tab,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { useWindowSize } from "usehooks-ts";

import { useFirebaseContext } from "../../providers/firebase";
import { useNavigate } from "react-router-dom";
import { IAccount } from "../../../../types";
import { CreateNewAccount } from "./components/CreateNewAccount";
import { UsageTab } from "./components/UsageTab";
import { PlansTab } from "./components/PlansTab";
import { Footer } from "./components/Footer";

import { DatasetsPreview } from "./components/DatasetsPreview";
import { ApikeyManagement } from "./components/ApikeyManagement";
import { OrganisationTab } from "./components/OrganisationTab";

interface IHeaderProps {
  setIsCreatingNewAccount: (isCreatingNewAccount: boolean) => void;
}
const Header = (props: IHeaderProps) => {
  const { signOut, state, setUser } = useFirebaseContext();
  const navigate = useNavigate();

  if (!state.user) {
    navigate("/");
    return <div />;
  }

  const actingAccount = state.accounts.find(
    (a) => a.id === state.user?.usingAccount
  ) as IAccount;

  if (!actingAccount) return <div />;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: 10,
        backgroundColor: "#172344",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          maxWidth: 960,
          justifyContent: "space-between",
          color: "white",
        }}
      >
        <div style={{ width: 24 }} />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
          }}
        >
          <Person />
          <div style={{ width: 4 }} />
          <span style={{ lineHeight: 1.75 }}>{state.user.email}</span>
        </div>
        <div style={{ width: 320 }}>
          <FormHelperText style={{ textAlign: "center", color: "white" }}>
            Organisation
          </FormHelperText>
          <Select
            value={actingAccount.id}
            style={{
              color: "white",
              textAlign: "center",
              backgroundColor: "#11386b",
              borderRadius: 4,
            }}
            className="organisation-select"
            onChange={async (e) => {
              if (e.target.value === "new")
                return props.setIsCreatingNewAccount(e.target.value === "new");
              if (!state.user) {
                console.log("tried to create a new account but user is null");
                return;
              }

              await setUser({
                ...state.user,
                usingAccount: e.target.value as string,
              });
            }}
            fullWidth
          >
            {state.accounts
              .sort((a, b) => a.creator.localeCompare(b.creator))
              .sort((a) => (a.name === "personal" ? 1 : -1))
              .map((account) => {
                return (
                  <MenuItem value={account.id} key={account.id}>
                    {account.name === "personal"
                      ? `${account.name} - ${account.creator}`
                      : account.name}
                  </MenuItem>
                );
              })}
            <MenuItem value="new">+ Create new organisation</MenuItem>)
          </Select>
        </div>
        <div
          style={{
            flex: 1,
            alignSelf: "center",
            textAlign: "right",
          }}
        >
          <Button onClick={signOut} style={{ color: "white" }}>
            Sign out
          </Button>
        </div>
        <div style={{ width: 24 }} />
      </div>
    </div>
  );
};

export const Dashboard = (props: any) => {
  const [selectedTab, setSelectedTab] = useState("plans");
  const [isCreatingNewAccount, setIsCreatingNewAccount] = useState(false);
  const navigate = useNavigate();
  const { state } = useFirebaseContext();
  const { height } = useWindowSize();

  if (!state.user) {
    navigate("/");
    return <div />;
  }
  if (state.accounts.length === 0) {
    return <div />;
  }

  const actingAccount = state.accounts.find(
    (a) => a.id === state.user?.usingAccount
  ) as IAccount;
  if (!actingAccount) {
    console.log("this should not have happened");
    console.log({ state });
    return <div />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height,
        alignContent: "flex-start",
      }}
    >
      <div style={{ paddingBottom: 16 }}>
        <Header setIsCreatingNewAccount={setIsCreatingNewAccount} />
      </div>
      {!actingAccount.userIds.includes(state.user.email) && (
        <div
          style={{
            textAlign: "center",
            color: "white",
            backgroundColor: "#16161d",
            width: 960,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 4,
            marginBottom: 16,
          }}
        >
          <div style={{ padding: 8 }}>
            You are acting as an admin, you are not inside this account
          </div>
        </div>
      )}
      <div style={{ flexGrow: 1 }}>
        <Paper
          elevation={2}
          style={{
            maxWidth: 960,
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            marginBottom: 16,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              backgroundColor: "#eef1f7",
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={(e, newSelectedTab) => setSelectedTab(newSelectedTab)}
              variant="fullWidth"
              style={{
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#7599ff",
                },
              }}
            >
              <Tab
                style={{
                  backgroundColor:
                    selectedTab === "plans" ? "white" : "inherit",
                }}
                value="plans"
                label="Plans"
              />
              <Tab
                value="usage"
                label="Usage & keys"
                style={{
                  backgroundColor:
                    selectedTab === "usage" ? "white" : "inherit",
                }}
              />
              <Tab
                value="organisation"
                label="Manage Users"
                style={{
                  backgroundColor:
                    selectedTab === "organisation" ? "white" : "inherit",
                }}
              />
            </Tabs>
          </div>
          {selectedTab === "organisation" && (
            <OrganisationTab account={actingAccount} user={state.user} />
          )}
          {selectedTab === "usage" && <UsageTab />}
          {selectedTab === "plans" && <PlansTab />}
        </Paper>

        {selectedTab === "plans" && (
          <Paper
            elevation={2}
            style={{
              maxWidth: 960,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 16,
            }}
          >
            <DatasetsPreview />
          </Paper>
        )}
        {selectedTab === "usage" && (
          <Paper
            elevation={2}
            style={{
              maxWidth: 960,
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 16,
              borderRadius: 4,
            }}
          >
            <ApikeyManagement />
          </Paper>
        )}
      </div>
      <Footer />

      {isCreatingNewAccount && (
        <CreateNewAccount setIsCreatingNewAccount={setIsCreatingNewAccount} />
      )}
    </div>
  );
};
