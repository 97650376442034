import React, { createContext, useContext, useState } from "react";
import { FAQ } from "../views/Dashboard/components/FAQ";
import { Terms } from "../views/Dashboard/components/Terms";
import { SummaryDescripter } from "../views/Dashboard/components/SummaryDiscripter";
import { IModelSummary } from "../../../types";
import { useFirebaseContext } from "./firebase";

interface IMisc {
  termsAreOpen: boolean;
  openTerms: (callback?: any) => void;
  openModelSummary: (modelSummary: IModelSummary) => void;
}
const MiscContext = createContext<IMisc>({
  termsAreOpen: false,
  openTerms: () => Promise.resolve(false),
  openModelSummary: () => null,
});

export const MiscProvider = ({ children }: { children?: any }) => {
  const [termsAreOpen, setTermsAreOpen] = useState(false);
  const [FAQsAreOpen, setFAQsAreOpen] = useState(false);
  const [modelSummary, setModelSummary] = useState<null | IModelSummary>(null);

  const {
    state: { user },
  } = useFirebaseContext();

  return (
    <MiscContext.Provider
      value={{
        termsAreOpen,
        openTerms: () => {
          console.log("set terms are open");
          console.log({ user });
          if (user) {
            setTermsAreOpen(true);
          }
        },
        openModelSummary: setModelSummary,
      }}
    >
      {children}
      <FAQ
        open={FAQsAreOpen}
        onClose={() => {
          setFAQsAreOpen(false);
        }}
      />
      {user && (
        <Terms
          open={termsAreOpen}
          user={user}
          onClose={() => setTermsAreOpen(false)}
        />
      )}
      {modelSummary && (
        <SummaryDescripter
          modelSummary={modelSummary}
          onClose={() => setModelSummary(null)}
        />
      )}
    </MiscContext.Provider>
  );
};

export const useMiscContext = () => useContext(MiscContext);
