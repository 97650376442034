import React from "react";
import { DateTime, Interval } from "luxon";
import { Chart } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  BarController,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  Legend,
  Tooltip,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-luxon";
import "chartjs-plugin-annotation";

import { IUsageItem } from "../../../../../types";
import { useFirebaseContext } from "../../../providers/firebase";
import { useStripeSubscription } from "../hooks/useStripeSubscription";
import { getIncludedApiUnits } from "../magic";

ChartJS.register(
  LineController,
  LineElement,
  LinearScale,
  BarController,
  CategoryScale,
  BarElement,
  PointElement,
  TimeScale,
  Legend,
  Tooltip
);

type MyDatum = {
  date: string;
  includedApiUnitsUsed?: number;
  threshold0?: number;
  threshold1?: number;
  threshold2?: number;
  total?: number;
  predictedTotal?: number;
};

const usageItemsToDailyUsage = (usageItems: IUsageItem[]) => {
  return usageItems.reduce<{ [startofDay: string]: number }>(
    (dailyUsageItems, usageItem) => {
      const startOfDay = DateTime.fromISO(usageItem.occurred.value)
        .startOf("day")
        .toISO();
      return {
        ...dailyUsageItems,
        [startOfDay]: (dailyUsageItems[startOfDay] || 0) + usageItem.charge,
      };
    },
    {}
  );
};

const rollingAverageSize = 7;

interface dailyUsageToChartDataProps {
  dailyUsages: { [startOfDay: string]: number };
  from: string;
  to: string;
  includedApiUnits: number;
  limit: number;
}
const dailyUsageToChartData = ({
  dailyUsages,
  from,
  to,
  includedApiUnits,
  limit,
}: dailyUsageToChartDataProps): MyDatum[] => {
  const days = Interval.fromDateTimes(
    DateTime.fromISO(from).startOf("day"),
    DateTime.fromISO(to).endOf("day")
  )
    .splitBy({ days: 1 })
    .map((interval) => interval.start);

  const daysWithUsage = days.filter((day) => {
    return dailyUsages[day.toISO()] !== undefined;
  });

  const measuredData = daysWithUsage.reduce<{ data: MyDatum[]; total: number }>(
    (reduction, day, i) => {
      const newTotal = reduction.total + dailyUsages[day.toISO()] || 0;

      const includedApiUnitsUsed = Math.min(newTotal, includedApiUnits);
      const threshold0 = Math.min(newTotal, limit * 0.8) - includedApiUnitsUsed;
      const threshold1 =
        Math.min(newTotal, limit * 0.9) - includedApiUnitsUsed - threshold0;
      const threshold2 =
        Math.min(newTotal, limit) -
        includedApiUnitsUsed -
        threshold0 -
        threshold1;
      return {
        data: [
          ...reduction.data,
          {
            date: day.toISO(),
            total: newTotal,
            includedApiUnitsUsed,
            threshold0,
            threshold1,
            threshold2,
          },
        ],
        total: reduction.total + dailyUsages[day.toISO()] || 0,
      };
    },
    { data: [], total: 0 }
  ).data;
  const daysWithoutUsage = days.filter((day) => {
    return dailyUsages[day.toISO()] === undefined;
  });

  const samples = measuredData.slice(-rollingAverageSize);
  const averageDifference = samples.reduce<number | null>(
    (averageDifference, sample, i, samples) => {
      const nextSample = samples[i + 1];
      if (!nextSample || !sample.total || !nextSample.total)
        return averageDifference;
      if (averageDifference === null) return nextSample.total - sample.total;

      return (averageDifference + (nextSample.total - sample.total)) / 2;
    },
    null
  );

  const totalMeasured =
    measuredData.length > 1 ? measuredData.slice(-1)[0].total : 0;

  const predictedData = totalMeasured
    ? daysWithoutUsage.map((day, i) => {
        if (day < DateTime.now())
          return { date: day.toISO(), predictedTotal: 0 };
        if (!totalMeasured) return { date: day.toISO(), predictedTotal: 0 };
        if (totalMeasured >= limit)
          return { date: day.toISO(), predictedTotal: limit };
        return {
          date: day.toISO(),
          predictedTotal: averageDifference
            ? Math.floor(totalMeasured + averageDifference * (i + 1))
            : 0,
        };
      })
    : daysWithoutUsage.map((d) => {
        return { date: d.toISO() };
      });

  return [...measuredData, ...predictedData];
};

const unixTimeToISO = (unixTime: number) => {
  return new Date(unixTime * 1000).toISOString();
};

const SubscriptionChart = () => {
  const { state } = useFirebaseContext();
  const { subscription: stripeSubscription } = useStripeSubscription(
    state,
    state.user?.usingAccount
  );

  if (!stripeSubscription || !state.usageItems || !state.subscription)
    return <div />;

  const plan = state.plans.find((p) => p.id === state.subscription?.productId);

  if (!plan) return <div />;

  const includedApiUnits = getIncludedApiUnits(plan);
  if (includedApiUnits === null) return <div />;

  const from = DateTime.fromISO(
    unixTimeToISO(stripeSubscription.current_period_start)
  ).toISO();
  const to = DateTime.fromISO(
    unixTimeToISO(stripeSubscription.current_period_end)
  ).toISO();

  const dailyUsages = usageItemsToDailyUsage(state.usageItems);
  const realData = dailyUsageToChartData({
    dailyUsages,
    from,
    to,
    includedApiUnits,
    limit: state.subscription.limit,
  });

  const defaultBarStyle = {
    type: "bar" as const,
    borderRadius: 3,
  };

  return (
    <Chart
      style={{ paddingBottom: 24 }}
      type={"bar"}
      data={{
        datasets: [
          {
            type: "line",
            data: [...realData.map((a) => state.subscription?.limit)],
            pointRadius: 0,
            borderColor: "rgba(0, 0, 0, 0)",
            spanGaps: true,
          },
          {
            ...defaultBarStyle,
            data: realData.map((a) => a.includedApiUnitsUsed),
            backgroundColor: "#7599ff",
          },
          {
            ...defaultBarStyle,
            data: realData.map((a) => a.threshold0),
            backgroundColor: "#74a310",
          },
          {
            ...defaultBarStyle,
            data: realData.map((a) => a.threshold1),
            backgroundColor: "#df7b00",
          },
          {
            ...defaultBarStyle,
            data: realData.map((a) => a.threshold2),
            backgroundColor: "#fa6161",
            borderRadius: 3,
          },
          {
            ...defaultBarStyle,
            data: realData.map((a) => a.predictedTotal),
            backgroundColor: "#ececec",
            borderColor: "#ccc",
            borderWidth: 1,
          },
        ],
        labels: realData.map(({ date }) => new Date(date)),
      }}
      options={{
        scales: {
          y: {
            beginAtZero: true,
            stacked: true,
          },
          x: {
            type: "time",
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12,
              maxRotation: 0,
            },
            stacked: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            bodyAlign: "center",
            titleAlign: "center",
            callbacks: {
              title: (context) => {
                const myDatum = realData[context[0].dataIndex];
                return DateTime.fromISO(myDatum.date).toFormat("MMM dd");
              },
              label: (context) => {
                const myDatum = realData[context.dataIndex];
                return (
                  "Units: " +
                  (
                    myDatum.total ??
                    myDatum.predictedTotal ??
                    0
                  ).toLocaleString()
                );
              },
            },
            xAlign: "center",
            yAlign: "bottom",
          },
        },
      }}
    />
  );
};

const EnterpriseChart = () => {
  const { state } = useFirebaseContext();

  if (!state.enterpriseSubscription) return <div />;

  const from = DateTime.now().startOf("month").toISO();
  const to = DateTime.now().endOf("month").toISO();

  const dailyUsages = usageItemsToDailyUsage(state.usageItems);
  const realData = dailyUsageToChartData({
    dailyUsages,
    from,
    to,
    includedApiUnits: state.enterpriseSubscription.limit,
    limit: state.enterpriseSubscription.limit,
  });

  const defaultBarStyle = {
    type: "bar" as const,
    borderRadius: 3,
  };

  return (
    <Chart
      style={{ paddingBottom: 24 }}
      type={"bar"}
      data={{
        datasets: [
          {
            type: "line",
            data: [...realData.map((a) => state.enterpriseSubscription?.limit)],
            pointRadius: 0,
            borderColor: "rgba(0, 0, 0, 0)",
            spanGaps: true,
          },
          {
            ...defaultBarStyle,
            data: realData.map((a) => a.includedApiUnitsUsed),
            backgroundColor: "#7599ff",
          },
          {
            ...defaultBarStyle,
            data: realData.map((a) => a.predictedTotal),
            backgroundColor: "#ececec",
            borderColor: "#ccc",
            borderWidth: 1,
          },
        ],
        labels: realData.map(({ date }) => new Date(date)),
      }}
      options={{
        scales: {
          y: {
            beginAtZero: true,
            stacked: true,
          },
          x: {
            type: "time",
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12,
              maxRotation: 0,
            },
            stacked: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            bodyAlign: "center",
            titleAlign: "center",
            callbacks: {
              title: (context) => {
                const myDatum = realData[context[0].dataIndex];
                return DateTime.fromISO(myDatum.date).toFormat("MMM dd");
              },
              label: (context) => {
                const myDatum = realData[context.dataIndex];
                return (
                  "Units: " +
                  (
                    myDatum.total ??
                    myDatum.predictedTotal ??
                    0
                  ).toLocaleString()
                );
              },
            },
            xAlign: "center",
            yAlign: "bottom",
          },
        },
      }}
    />
  );
};

export const DailyUsageChart = () => {
  const { state } = useFirebaseContext();

  return (
    <div key={state.user?.usingAccount}>
      {state.subscription && <SubscriptionChart />}
      {state.enterpriseSubscription && <EnterpriseChart />}
      {!state.subscription && !state.enterpriseSubscription && (
        <div>No Subscription</div>
      )}
    </div>
  );
};
