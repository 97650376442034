import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  IconButton,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { useFirebaseContext } from "../../../providers/firebase";
import * as EmailValidator from "email-validator";

interface ICreateNewAccount {
  setIsCreatingNewAccount: (isCreatingNewAccount: boolean) => void;
}
export const CreateNewAccount = (props: ICreateNewAccount) => {
  const { setIsCreatingNewAccount } = props;
  const { state, createAccount } = useFirebaseContext();
  const [userAddValue, setUserAddValue] = useState("");
  const [users, setUsers] = useState<string[]>([]);
  const [accountNameError, setAccountNameError] = useState("");
  const [accountName, setAccountName] = useState("");
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  useEffect(() => {
    if (accountName.length < 4)
      return setAccountNameError(
        "Organisation name needs to be 4 or more characters"
      );
    if (accountName.length > 24)
      return setAccountNameError(
        "Organisation name cannot be longer than 24 characters"
      );
    setAccountNameError("");
  }, [accountName]);

  useEffect(() => {
    setEmailIsInvalid(false);
  }, [userAddValue]);

  const addUser = () => {
    if (userAddValue === state?.user?.email) return setUserAddValue("");
    if (EmailValidator.validate(userAddValue)) {
      setUsers([...users.filter((u) => u !== userAddValue), userAddValue]);
      setUserAddValue("");
    } else {
      setEmailIsInvalid(true);
    }
  };

  return (
    <Dialog open onClose={console.log}>
      <DialogTitle id="form-dialog-title">
        Create a new organisation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Organisations allow you to share subscriptions and API keys with your
          peers. In this form you can create a new organisation, you will need
          let us know the organisation name and the emails of the peers you wish
          to be a part of the organisation.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Organisation name"
          helperText={accountNameError ? accountNameError : ""}
          fullWidth
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          error={!!accountNameError}
        />
        <div style={{ height: 24 }} />
        <div>
          <TextField
            style={{ width: "calc(100% - 80px)" }}
            label="Add peers emails here"
            helperText={
              emailIsInvalid
                ? "Not a valid email"
                : "Pressing Enter will add the user."
            }
            error={emailIsInvalid}
            value={userAddValue}
            onChange={(e) => setUserAddValue(e.target.value.toLowerCase())}
            onKeyPress={(e) => {
              if (e.code === "Enter") return addUser();
            }}
          />
          <IconButton disabled={state.isUpdatingApikey} onClick={addUser}>
            <AddCircle style={{ paddingTop: 3, color: "#7599ff" }} />
          </IconButton>
        </div>
        <div style={{ height: 24 }} />
        <div>
          <span style={{ fontSize: 10.5, color: "grey" }}>
            No need to get this list exactly right on creation. You can
            add/remove users from an organisation at anytime.
          </span>
        </div>
        <Chip
          label={state?.user?.email}
          style={{ marginRight: 10, marginTop: 10 }}
        />
        {users.map((user) => {
          return (
            <Chip
              key={user}
              label={user}
              onDelete={() => setUsers(users.filter((u) => u !== user))}
              style={{ marginRight: 10, marginTop: 10 }}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsCreatingNewAccount(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            setIsCreatingAccount(true);
            if (!state.user) {
              console.log("tried to create an account but no user exists");
              return;
            }
            await createAccount({
              name: accountName,
              userIds: [state.user.email, ...users],
              apikeys: [],
              isPersonal: false,
              creator: state.user.email,
              subscriptionId: null,
              subscriptions2: [],
              enterpriseSubscriptionId: null,
            });
            setIsCreatingAccount(false);
            setIsCreatingNewAccount(false);
          }}
          style={{ color: "white" }}
          disabled={!!accountNameError || emailIsInvalid || isCreatingAccount}
          color="primary"
          variant="contained"
        >
          Create organisation
        </Button>
      </DialogActions>
    </Dialog>
  );
};
