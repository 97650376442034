import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoogleLoginButton,
  GithubLoginButton,
  MicrosoftLoginButton,
} from "react-social-login-buttons";
import { useEnquiryContext } from "../providers/EnquiryModal";
import { useFirebaseContext } from "../providers/firebase";

export const SignIn = () => {
  const { signInWithGoogle, signInWithGithub, signInWithMicrosoft } =
    useFirebaseContext();
  const { openEnterpriseModal } = useEnquiryContext();
  const { state } = useFirebaseContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.user) {
      navigate("/dashboard");
    }
  }, [state.user]);

  return (
    <div
      style={{
        width: 600,
        left: window.innerWidth / 2 - 300,
        position: "absolute",
        top: 200,
        textAlign: "center",
      }}
    >
      <span
        style={{
          color: "rgba(256, 256, 256, .7)",
          fontWeight: "bold",
          fontSize: 24,
        }}
      >
        Point Forecast API
      </span>
      <div style={{ height: 20 }} />
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <GoogleLoginButton
          style={{ width: 300, alignSelf: "center" }}
          onClick={signInWithGoogle}
        >
          Sign in with Google
        </GoogleLoginButton>
        <GithubLoginButton
          style={{ width: 300, alignSelf: "center" }}
          onClick={signInWithGithub}
        >
          Sign in with Github
        </GithubLoginButton>
        <MicrosoftLoginButton
          style={{ width: 300, alignSelf: "center" }}
          onClick={signInWithMicrosoft}
        >
          Sign in with Microsoft
        </MicrosoftLoginButton>
      </div>
      <button onClick={() => openEnterpriseModal()}>Enquire</button>
    </div>
  );
};
