import React from "react";
import { Button } from "@material-ui/core";

import { useMiscContext } from "../../../providers/Misc";

export const Footer = () => {
  const { openTerms } = useMiscContext();

  return (
    <div
      style={{
        backdropFilter: "blur(5px)",
        backgroundColor: "#182444",
        textAlign: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          padding: 6,
        }}
      >
        <Button
          style={{ flex: 1, maxWidth: 220 }}
          target="_blank"
          href="https://forecast-docs.metoceanapi.com/"
          className="text-button"
        >
          API Documentation
        </Button>
        <div style={{ width: 48 }} />
        <Button
          style={{ flex: 1, maxWidth: 220 }}
          onClick={(a) => openTerms()}
          className="text-button"
        >
          Terms of service
        </Button>
      </div>
      <img
        src="/allbrands.svg"
        alt="MetOcean Solutions (Division of Metservice)"
        style={{ height: 40, padding: 20 }}
      />
    </div>
  );
};
