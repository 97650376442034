/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useState,
  useLayoutEffect,
} from "react";
import { IModelSummary } from "../../../types";

interface IMetoceanAPI {
  summaries: IModelSummary[];
}
const MetoceanAPIContext = createContext<IMetoceanAPI>({
  summaries: [],
});

interface IMetoceanAPIState {
  summaries: IModelSummary[];
}
export const MetoceanAPIProvider = ({ children }: { children?: any }) => {
  const [state, setState] = useState<IMetoceanAPIState>({
    summaries: [],
  });

  useLayoutEffect(() => {
    fetch(
      `https://forecast-v2.metoceanapi.com/modelsummaries/?sort=area,asc&accessLevel=9001`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((a) => a.json() as Promise<IModelSummary[]>)
      .then((summaries) =>
        summaries
          .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          })
          .sort((a, b) => (a.accessLevel > b.accessLevel ? 1 : -1))
      )
      .then((summaries) => setState({ ...state, summaries }));
  }, []);

  return <MetoceanAPIContext.Provider value={state} children={children} />;
};

export const useMetoceanAPIContext = () => useContext(MetoceanAPIContext);
