import { useState } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useSnackbar } from "notistack";

import { useFirebaseContext } from "../../../providers/firebase";
import { useEmailInput } from "../hooks/useEmailInput";
import { useEnquiryContext } from "../../../providers/EnquiryModal";

const maxNotesLength = 2048;
const gap = 30;
const dataInterestList = [
  "Long-range forecasts",
  "Custom local domain",
  "High-Priority Support",
  "High Call Volumes",
  "High-resolution ROMS (Regional Ocean Modeling System)",
  "MetService NZ WRF Model of the Day",
];

export const EnterpriseModal = () => {
  const { enterpriseModalIsOpen, closeEnterpriseModal } = useEnquiryContext();
  const { sendEnterpriseEnquiry } = useFirebaseContext();
  const { email, setEmail, emailIsValid, checkEmail } = useEmailInput();
  const [dataInterests, setDataInterests] = useState<string[]>([]);
  const [enquiryNotes, setEnquiryNotes] = useState<string>("");
  const [organisationName, setOrganisationName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={enterpriseModalIsOpen}>
      <DialogContent>
        <DialogContentText>
          Thank you for your interest. Please fill in the form below and we will
          get back to you.
        </DialogContentText>
        <div style={{ height: gap }} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1 }}>
            <div style={{ fontSize: "0.9em" }}>Email*</div>
            <TextField
              required
              style={{ width: "100%" }}
              placeholder="enquiries@metocean.co.nz"
              helperText={!emailIsValid && "Not a valid email"}
              error={!emailIsValid}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div style={{ height: gap }} />
            <div style={{ fontSize: "0.9em" }}>Name</div>
            <TextField
              required
              placeholder="Edward Lorenz"
              style={{ width: "100%" }}
              onChange={(e) => setName(e.target.value)}
            />
            <div style={{ height: gap }} />
            <div style={{ fontSize: "0.9em" }}>Organisation</div>
            <TextField
              required
              placeholder="Texas Institute of Technology & Science"
              style={{ width: "100%" }}
              onChange={(e) => setOrganisationName(e.target.value)}
            />
            <div style={{ height: gap }} />
            <div
              style={{ fontSize: "0.9em", color: "grey", textAlign: "center" }}
            >
              Please let us know what types of data you are interested in
            </div>
            <div style={{ height: 5 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {dataInterestList.map((type) => {
                return (
                  <div key={type} style={{ width: "50%", marginBottom: 10 }}>
                    <FormControlLabel
                      value={type}
                      control={
                        <Checkbox
                          size={"small"}
                          onChange={(e) => {
                            setDataInterests(
                              e.target.checked
                                ? [...dataInterests, type]
                                : dataInterests.filter((t) => t !== type)
                            );
                          }}
                        />
                      }
                      label={<span style={{ fontSize: "0.8em" }}>{type}</span>}
                      labelPlacement="end"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ width: gap }} />
          <div style={{ flex: 1 }}>
            <div style={{ fontSize: "1.1em" }}>How can we help?</div>
            <div style={{ height: 5 }} />
            <TextareaAutosize
              aria-label="Enquiry notes"
              minRows={20}
              placeholder="Enter your message"
              style={{ width: "100%" }}
              maxLength={maxNotesLength}
              onChange={(e) => setEnquiryNotes(e.target.value)}
            />
            <div
              style={{
                fontSize: "0.6em",
                lineHeight: 0.2,
                paddingLeft: 5,
                color: "grey",
              }}
            >
              Remaining characters: {maxNotesLength - enquiryNotes.length}
            </div>
          </div>
        </div>
        <IconButton
          onClick={closeEnterpriseModal}
          style={{ position: "absolute", top: 5, right: 5 }}
        >
          <Close />
        </IconButton>
      </DialogContent>
      <DialogActions style={{ padding: 15 }}>
        <Button
          onClick={async () => {
            const emailIsValid = checkEmail();

            if (emailIsValid === false) return;
            setIsLoading(true);
            await sendEnterpriseEnquiry({
              email,
              dataInterests,
              notes: enquiryNotes,
              name,
              organisationName,
            });
            enqueueSnackbar("Enquiry sent!", {
              variant: "success",
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
            });
            closeEnterpriseModal();
            setIsLoading(false);
          }}
          disabled={isLoading}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
